import videojs from 'video.js';

const $tipsSlider = $('#tips_slider');
const $securityBox = $('#bezpecnost .security_box');
const $securityHidenBoxes = $('#bezpecnost .security_hide');
const $securityMoreButton = $('#bezpecnost .security_more');
const $articleBox = $('#clanky .article_box .article_content');
const $windowWidth = $(window).width();
const $videosSlider = $('#videos_slider');
const $videosSliderTitle = $('#slider_title-wrapper');
const $threatBoxIcon = $('#cards .card-box_icon');
const $threatBoxTitle = $('#cards .card_title');

function initTipsSlider() {
    $tipsSlider.slick({
        slidesToShow: 1,
        infinite: false,
        dots: true,
        fade: true,
        speed: 500,
        prevArrow: false,
        nextArrow: '<a class="slick-next slick-arrow">Ďalší tip</a>',
        customPaging: function(slick, index) {
            return '<button type="button">Náš tip '+ ++index +'</button>';
        }
    });

    $tipsSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        if ($windowWidth <= 420) {
            if (currentSlide < nextSlide) {
                if (nextSlide >= 2) {
                    $tipsSlider.removeClass('slick-dots-move--right').addClass('slick-dots-move--left');
                }
            } else {
                if (nextSlide <= 2) {
                    $tipsSlider.removeClass('slick-dots-move--left').addClass('slick-dots-move--right');
                }
            }
        }
    });
}

function setEqualHeights() {
    if ($windowWidth >= 768) {
        $securityBox.equalHeights();
        $articleBox.equalHeights();
        $threatBoxIcon.equalHeights();
        $threatBoxTitle.equalHeights();
    }
}

function swipeForSliderDotsMobile() {
    if ($windowWidth <= 420) {
        const $tipsSliderDots = $tipsSlider.find('.slick-dots');

        $tipsSliderDots.swipe( {
            swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                switch (direction) {
                    case 'left':
                        $tipsSlider.removeClass('slick-dots-move--right').addClass('slick-dots-move--left');
                        break;
                    case 'right':
                        $tipsSlider.removeClass('slick-dots-move--left').addClass('slick-dots-move--right');
                        break;
                }
            }
        });
    }
}

function showMoreSecurityBoxes() {
    $securityMoreButton.on('click', function (e) {
        e.preventDefault();
        $securityHidenBoxes.fadeIn();
        $securityMoreButton.fadeOut();
    });
}

function initVideosSlider() {
    $videosSlider.slick({
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 1,
        dots: true,
        prevArrow: $('.video-arrow.video-arrow--prev'),
        nextArrow: $('.video-arrow.video-arrow--next')
    });

    $videosSlider.find('.slick-dots').addClass('video-dots');

    $videosSliderTitle.children().equalHeights().not('.active').hide();

    $videosSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        const videoWrapper = $videosSlider.find(`.slick-slide[data-slick-index="${currentSlide}"] .video-js`)[0];
        const player = videojs(videoWrapper);

        if(player) {
            player.pause();
            player.initChildren();
        }

        const nextSlideOrder = $videosSlider.find(`.slick-slide[data-slick-index="${nextSlide}"] .video-box`).data('order');

        $videosSliderTitle.find('.active').fadeOut(350, () => {
            $videosSliderTitle.find(`#title-slide-${nextSlideOrder}`).fadeIn(350).addClass('active');
        }).removeClass('active');
    });
}

$(function () {
    initTipsSlider();
    setEqualHeights();
    swipeForSliderDotsMobile();
    showMoreSecurityBoxes();
    initVideosSlider();
});
